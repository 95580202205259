.container {
  width: 900px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
}

.container_big {
  width: 1024px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 1100px) {
  .container_big {
    width: 900px;
  }
}

@media (max-width: 900px) {
  .container, .container_big {
    margin: 0;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

@media (max-width: 780px) {
  .container, .container_big {
    padding-left: 0;
    padding-right: 0;
  }
}

.loader {
  animation: loaderAnim ease-in-out 1s infinite;
}

@keyframes loaderAnim {
  0% {
    transform: rotate(0turn);
  }

  100% {
    transform: rotate(1turn);
  }
}