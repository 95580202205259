.userGuide {
  color: #333333;
  font-size: 16px;
  max-width: 990px;
  margin: 0 auto;
}

.userGuide img {
  margin: 30px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #333333;
}

.subTitle {
  font-size: 18px;
  margin-bottom: 50px;
  max-width: 900px;
}

.item {
  font-size: 18px;
  line-height: 1.6;
  display: flex;
  margin-bottom: 100px;
  align-items: center;
}

.items :first-child {
  align-items: flex-start;
}

.info {
  width: 50%;
}

.image {
  text-align: center;
}

.userGuideTab img {
  display: block;
  margin: 30px auto;
  width: 90%;
}

.images {
  display: flex;
  justify-content: center;
}

.images img {
  max-width: 250px;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}

.action {
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.action a {
  color: #1e90ff;
}

.button {
  margin-top: 25px;
}

.button a {
  background-color: #1e90ff;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 45px;
  box-sizing: border-box;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
}

@media (max-width: 990px) {
  .item {
    font-size: 18px;
  }
  .subTitle {
    font-size: 22px;
  }
  .title {
    font-size: 28px;
  }
}

@media (max-width: 880px) {
  .images {
    justify-content: center;
  }
  .images img {
    width: 30%;
    max-width: inherit;
  }
  .userGuide img {
    max-width: calc(100% - 10px);
    text-align: center;
    margin: 0 auto;
    margin-bottom: 1em;
  }
  .item {
    flex-direction: column;
  }
  .info {
    width: 100%;
    padding: 0;
    order: 2;
  }
  .image {
    width: 100%;
    padding: 0;
    margin-bottom: 50px;
    order: 1;
  }
  .image img {
    max-width: 350px;
  }
}

@media (max-width: 600px) {
  .images {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .images img {
    width: 70%;
  }
}

@media (max-width: 400px) {
  .images img {
    width: 100%;
  }
}