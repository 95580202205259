.howItWorks {
  color: #333333;
  font-size: 16px;
}

.howItWorks img {
  max-width: calc(70% - 80px);
  text-align: center;
  box-shadow: 0px 0px 30px 10px #938C93;
  margin: 30px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #333333;
}

.subTitle {
  font-size: 18px;
  margin-bottom: 50px;
  max-width: 900px;
}

.item {
  font-size: 18px;
  line-height: 1.6;
  display: flex;
  margin-bottom: 100px;
  align-items: center;
}

.items :first-child {
  align-items: flex-start;
}

.info {
  width: 50%;
}

.image {
  text-align: center;
  width: 50%;
}

.image svg {
  max-width: 660px;
  width: 95%;
  padding: 10px;
}

.imageSmall {
  text-align: center;
  width: 50%;
}

.imageSmall svg {
  max-width: 200px;
  width: 95%;
  padding: 10px;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.icons a {
  margin: 0 30px;
}

.icons svg {
  width: 100px;
  box-sizing: border-box;
}

.action {
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.action a {
  color: #1e90ff;
}

.button {
  margin-top: 25px;
}

.button a {
  background-color: #1e90ff;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 45px;
  box-sizing: border-box;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
}

@media (max-width: 990px) {
  .howItWorks img {
    max-width: calc(100% - 10px);
    text-align: center;
    box-shadow: 0px 0px 10px 0px #938C93;
    margin: 0;
    margin-bottom: 1em;
  }
  .item {
    flex-direction: column;
  }
  .info {
    width: 100%;
    padding: 0;
    order: 2;
  }
  .image {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
    order: 1;
  }
  .image img {
    max-width: 350px;
  }
  .icons {
    order: 1;
    margin-bottom: 30px;
  }
  .imageSmall {
    margin-bottom: 30px;
  }
  .icons a {
    margin: 0 20px;
  }
  .icons svg {
    width: 70px;
    box-sizing: border-box;
  }
}