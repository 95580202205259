.csToken {
  color: #333333;
  font-size: 18px;
  max-width: 990px;
  margin: 0 auto;
}

.csToken :global(.ant-steps-item-title) {
  font-size: 18px !important;
  font-weight: bold !important;
}

.csToken :global(.ant-steps-item-description) {
  font-size: 18px !important;
  margin-bottom: 30px;
}

.csToken :global(.ant-steps-item-icon) {
  transform: scale(1.2);
}