.title {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 12px;
}

.titleWrap {
  max-width: 990px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.collapse {
  background-color: #141414;
  max-width: 990px;
  margin: 0 auto;
}

.panel {
  margin-bottom: 30px;
  padding: 20px;
  font-size: 16px;
  line-height: 25px;
  border-radius: 15px;
  background-color: #303030;
}

.panel:last-child {
  border-bottom: none;
}

.panel ul {
  list-style-type: none;
  padding-left: 0;
}
.panel {
  max-width: 800px;
}

.panel p:last-child {
  margin-bottom: 0;
}

.panel img {
  margin-bottom: 10px;
  margin-top: 10px;
}

.panel a {
  text-decoration: underline;
}

:global(.faq h2) {
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 15px;
}

.icon {
  color: #1e90ff !important;
  font-size: 18px !important;
}

.action {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 18px;
}

.action a {
  color: #1e90ff;
}