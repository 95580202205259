.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.paramName {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}

.headerValue {
  text-transform: uppercase;
  font-size: 18px;
  word-wrap: break-word;
  max-width: 65%;
  text-align: right;
}

.leaderWrap, .choiceWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.choiceValue, .leaderValue {
  max-width: 65%;
  word-wrap: break-word;
}

.listOfVoters {
  margin-top: 15px;
}

.listOfVotersHeader {
  display: flex;
  border-bottom: 1px solid #434343;
  padding: 5px;
  padding-top: 0;
  margin-bottom: 5px;
}

.listOfVotersItem {
  display: flex;
  padding: 5px;
}

.listOfVotersValue {
  width: 45%;
  word-wrap: break-word;
  padding-right: 10px;
}

.listOfVotersSupport {
  width: 25%;
  padding-right: 10px;
}

.listOfVotersAction {
  width: 30%;
  text-align: right;
}

.listOfVotersTitle {
  display: none;
}

.label {
  text-transform: none;
}

@media (max-width: 780px) {
  .leaderWrap, .choiceWrap, .header {
    display: block;
    margin-top: 15px;
  }

  .choiceValue, .leaderValue {
    max-width: 100%;
  }
  .header {
    display: initial;
  }
  .headerValue {
    max-width: 100%;
    text-align: left;
    display: inline;
  }
  .paramName {
    display: block;
    margin-right: 5px;
  }
  .listOfVotersHeader {
    display: none;
  }
  .listOfVotersItem {
    display: block;
    padding: 0;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .listOfVotersValue, .listOfVotersSupport, .listOfVotersAction {
    width: 100%;
    padding: 0;
    text-align: left;
  }
  .listOfVotersAnotherValue {
    padding-top: 25px;
  }
  .listOfVoters {
    background-color: #1d1c1c;
    padding: 0 10px;
  }
  .listOfVotersTitle {
    display: block;
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
  }
}

@media (max-width: 380px) {
  .paramName, .headerValue {
    font-size: 16px;
  }
}